<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('licensetype.name')"
        prop="name"
        :rules="[{ required: true, message: 'Name is required'}]"
      >
        <el-input type="text" v-model="form.name"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Departament')"
        prop="departament"
      >
        <el-input type="text" v-model="form.departament"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Payment To')"
        prop="paymentTo"
      >
        <el-input type="text" v-model="form.paymentTo"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Address')"
        prop="address"
      >
        <el-input type="textarea" v-model="form.address" :rows="4"></el-input>
      </el-form-item>
      
      <el-form-item
        :label="$t('addressTwo')"
        prop="addressTwo"
      >
        <el-input type="textarea" v-model="form.addressTwo" :rows="4"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Observations ')"
        prop="observations"
      >
        <el-input type="textarea" v-model="form.observations" :rows="3"></el-input>
      </el-form-item>

      <el-button type="primary" @click="(!data)?submitForm('form'):updateForm('form')">Save Register</el-button>
    </el-form>
  </div>
</template>

<script>
import licenseType from "@/services/api/licenseType";

export default {
  props: ["data"],
  data() {
    return {
      cities: [],
      form: {
        id: null,
        name: null,
        departament: null,
        paymentTo: null,
        address: null,
        addressTwo: null,
        observations: null
      }
    };
  },
  mounted() {
    if (this.data) {
      this.form.id = this.data.id;
      this.form.name = this.data.name;
      this.form.departament = this.data.departament;
      this.form.paymentTo = this.data.paymentTo;
      this.form.address = this.data.address;
      this.form.addressTwo = this.data.addressTwo;
      this.form.observations = this.data.observations;
    }
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          licenseType
            .create(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          licenseType
            .update(this.form)
            .then(response => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error"
              });
            });
        } else {
          return false;
        }
      });
    }
  }
};
</script>

<style lang="scss">
</style>
